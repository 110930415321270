import Cookies from 'js-cookie'
import router from './router'
import store from '@/store'
import { isIOS } from '@/utils/detect'

const cookieUserInfo = Cookies.get('userInfo')

router.beforeEach((to, from, next) => {
  // 如果是安卓设备则直接跳转到空白页
  if (!isIOS && to.fullPath !== '/androidPage') {
    next({ path: '/androidPage' })
    return
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.matched.some(record => record.meta.requireAuth)) {
    const { userInfo = {} } = store.state
    const { punterID } = userInfo
    if (punterID) {
      return next()
    }
    if (cookieUserInfo) {
      const u = JSON.parse(cookieUserInfo)
      store.commit('recordInfo', {
        type: 'userInfo',
        value: u
      })
      return next()
    }
    window.webkit.messageHandlers.logout.postMessage('')
  } else {
    next()
  }
})

// 解决更新代码后 Error:Loading chunk {n} failed.的问题
router.onError(err => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = err.message.match(pattern)
  if (isChunkLoadFailed) {
    const chunkBool = sessionStorage.getItem('chunkError')
    const nowTimes = Date.now()
    if (chunkBool === null || (chunkBool && nowTimes - parseInt(chunkBool) > 60000)) {
      // 路由跳转报错,href手动跳转
      sessionStorage.setItem('chunkError', 'reload')
      const targetPath = router.history.pending.fullPath
      location.href = location.protocol + '//' + location.host + targetPath
    } else if (chunkBool === 'reload') {
      // 手动跳转后依然报错,强制刷新
      sessionStorage.setItem('chunkError', Date.now())
      location.reload(true)
    }
  }
})
export default router
